import api from "./api";

export const COLUMNS = [
  { label: "user name", accessor: (row: any) => row?.username },
  { label: "email", accessor: (row: any) => row?.email },
  {
    label: "Status",
    accessKey: "active_status",
    accessor: (row: any) => row?.is_active,
  },
  {
    label: "Demo Account Status",
    accessKey: "is_demo_account",
    accessor: (row: any) => row?.user_profile?.is_demo_account,
  },
];

export const getAllUsers = async (page?: any, limit?: any, search?: any) => {
  const encodedSearch = encodeURIComponent(search);
  const response = await api.get(
    `/User/getUsersList/?page=${page}&page_size=${limit}&username=${encodedSearch}`
  );
  return response;
};

export const changeUserStatus = async (url: string) => {
  const response = await api.get(url);
  return response;
};

export const changeUserIsDemo = async (url: string) => {
  const response = await api.get(url);
  return response;
};
