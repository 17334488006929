import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useMutation, useQuery } from "react-query";
import Loader from "src/components/Loader/Loader";
import { useDispatch } from "react-redux";
import { openSnackbar } from "src/redux/snackbar";
import SearchInput from "src/components/SearchInput/SearchInput";
import Header from "src/components/Header/Header";
import { COLUMNS, addNotification, deleteNotification, getAllNotifications, updateNotification } from "src/services/notifications";
import TableData from "src/components/Table/TableData";
import Modal from "src/components/Modal/Modal";
import { isValidInput } from "src/services/common";
import DeleteConformation from "src/components/Delete/DeleteConformation";
import { getUsersList } from "src/services/dropDownOptins";



const Notifications = () => {
  const [userNotifications, setUserNotifications] = useState([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [notificationId, setNotificationId] = useState<string>("");
  const [notification, setNotification] = useState({
    user: null,
    message: "",
    is_read: false,
  });
  const [usersList, setUsersList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deletedItem, setDeletedItem] = useState<string>("");

  const { isLoading: notificationsLoading, refetch: reFetchNotifications, isFetching } = useQuery(
    ["notifications"],
    () =>
      getAllNotifications(page, limit),
    {
      enabled: false,
      onSuccess: (data) => {
        setUserNotifications(data);
        if (deletedItem !== "") {
          setDeletedItem("")
        }
      },
    }
  );


  const { isLoading: usersLoading, refetch: refetchAllUsers } = useQuery(
    ["userLists"],
    () =>
      getUsersList(),
    {
      enabled: false,
      onSuccess: (data) => {
        setUsersList(data?.data?.results);
      },
    }
  );

  useEffect(() => {
    refetchAllUsers();
  }, [])

  useEffect(() => {
    reFetchNotifications();

  }, [limit, page]);
  const dispatch = useDispatch();

  const handleDelete = async (id: string) => {
    const resp = await DeleteConformation();

    if (resp) {
      setDeletedItem(id)
      deleteMutate(id);
    }
  };



  const { mutate, isLoading, reset } = useMutation(
    (data: any) => addNotification(data),
    {
      onError: (error) => {
        dispatch(
          openSnackbar({
            message: "Error occures",
            title: "Error",
            type: "error",
          })
        );
        reset();
      },
      onSuccess: (resp) => {
        reFetchNotifications();
        setIsSubmitted(false);
        setIsOpen(false);
        dispatch(
          openSnackbar({
            message: "Notification added successfylly!",
            title: "Success",
            type: "success",
          })
        );
      },
    }
  );

  const {
    mutate: update,
    reset: updateReset,
  } = useMutation((data: any) => updateNotification(data, notificationId), {
    onError: (error) => {
      dispatch(
        openSnackbar({
          message: error,
          title: "Error",
          type: "error",
        })
      );

      updateReset();
    },

    onSuccess: () => {
      updateReset();
      dispatch(
        openSnackbar({
          message: "Notification Updated Successfully! ",
          title: "Success",
          type: "success",
        })
      );
      setIsSubmitted(false);
      setOpenUpdateModal(false);
      setNotification({
        user: null,
        message: "",
        is_read: false,
      });
      reFetchNotifications();
    },
  });

  const { mutate: deleteMutate, reset: deleteReset } = useMutation(
    (id: string) => deleteNotification(id),
    {
      onError: (error) => {
        dispatch(
          openSnackbar({
            message: error,
            title: "Error",
            type: "error",
          })
        );

        reset();
      },

      onSuccess: () => {
        dispatch(
          openSnackbar({
            message: "Notification Deleted Successfully! ",
            title: "Success",
            type: "success",
          })
        );
        deleteReset();
        reFetchNotifications();
      },
    }
  );



  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (!isValidInput(notification.message) || notification.user === null) {
      return
    }

    openUpdateModal ? update(notification) : mutate(notification);
    setIsSubmitted(false);

  };

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <PageTitleWrapper>
        <Header
          label="Notifications"
          name={"notifications"}
          btnName={"Add New"}
          setIsopen={() => {
            setNotification({
              user: null,
              message: "",
              is_read: false,
            })
            setIsOpen(true);
          }}
        />


      </PageTitleWrapper>
      <Container maxWidth="lg">

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            {notificationsLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "40vh",
                  alignItems: "center",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <Card>
                <Divider />
                <TableData
                  columns={COLUMNS}
                  data={userNotifications}
                  page={page}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  onDelete={handleDelete}
                  openUpdateModal={setOpenUpdateModal}
                  setUpdatedId={setNotificationId}
                  setUpdatedState={(item) => setNotification({
                    message: item.message,
                    user: item.user,
                    is_read: item.is_read
                  })}
                  editLabel={"Edit Notification"}
                  deleteLabel={"Delete Notification"}
                  estimateBtnDisbale={false}
                  estimateBtn={false}
                  deletedItem={deletedItem}
                  isFetching={isFetching}
                />
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>


      <Modal
        open={isOpen || openUpdateModal}
        onClose={() => {
          openUpdateModal ? setOpenUpdateModal(false) : setIsOpen(false);
        }}
        title={`${openUpdateModal ? "Update Notification" : "Add New Notification"}`}

      >

        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          // sx={{ display: "flex", flexDirection: "column" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "350px",
            margin: "auto",
            paddingX: "10px"
          }}
        >

          <SearchInput
            id="users_list"
            name="user"
            label="Select user"
            multiple={false}
            sx={{
              marginBottom: "0px",
            }}
            options={usersList.map((item) => ({
              label: item?.username,
              value: item.id
            }))}
            onChange={(e) => {
              setNotification({
                ...notification,
                user: e.value
              })
            }}
            error={isSubmitted && !isValidInput(notification.user)}
            value={
              notification.user &&
              (() => {
                const matchingItem = usersList.find(
                  (item) => item.id === notification.user
                );

                return matchingItem
                  ? {
                    label: matchingItem.username,
                    value: matchingItem.id,
                  }
                  : null;
              })()
            }
            isLoading={usersLoading}

          />
          <TextField
            autoComplete="off"
            margin="normal"
            required
            fullWidth
            label="Message"
            name="message"
            type="text"
            onChange={(e) => setNotification({
              ...notification,
              message: e.target.value
            })}
            sx={{
              marginBottom: "20px",
            }}
            error={isSubmitted && !isValidInput(notification.message)}
            value={notification.message}
          />

          <FormControl sx={{
            marginBottom: "20px"
          }}>
            <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={false}
              name="radio-buttons-group"
              onChange={(e) => setNotification({
                ...notification,
                is_read: e.target.value === "true"
              })}
              value={notification.is_read.toString()}
            >
              <FormControlLabel control={<Radio />} label="Read" value="true" />
              <FormControlLabel control={<Radio />} label="Not Read" value="false" />
            </RadioGroup>
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              marginBottom: "20px",
            }}
            disabled={isLoading}
          >
            {(() => {
              if (isLoading) {
                return <Loader color={"inherit"} size={20} />;
              } else if (openUpdateModal) {
                return "Update";
              } else {
                return "Add";
              }
            })()}

          </Button>
        </Box>

      </Modal>

    </>
  );
};

export default Notifications;
