import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  refresh: null,
  access: null,
};
export const userSlice: any = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: any, action: any) => {
      const { name, refresh, access } = action.payload;
      state.name = name;
      state.refresh = refresh; 
      state.access = access;
    },
    removeUser: (state: any) => {
      state.name = null; 
      state.refresh = null; 
      state.access = null;
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
