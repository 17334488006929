import { Avatar } from '@mui/material'
import { deepPurple } from '@mui/material/colors';
import React from 'react'




const BackgroundLetterAvatars = ({ name }) => {
    return (
        name ?
            <Avatar sx={{ bgcolor: deepPurple[500], textTransform: 'uppercase' }}>{name[0]}</Avatar> : <></>
    )
}

export default BackgroundLetterAvatars