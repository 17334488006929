import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TablePagination,
  Pagination,
  Button,
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LoopIcon from "@mui/icons-material/Loop";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Loader from "../Loader/Loader";

type Props = {
  columns: any[];
  data?: any;
  page: number;
  setPage: any;
  limit: number;
  setLimit: any;
  onDelete?: any;
  openUpdateModal?: any;
  setUpdatedId?: any;
  setUpdatedState?: any;
  editLabel?: string;
  deleteLabel?: string;
  disabled?: boolean;
  estimateBtnDisbale?: boolean;
  estimateBtn?: boolean;
  campaignStatusBtn?: any;
  loading?: boolean;
  loadingIsDemo?: boolean;
  usersStatusBtn?: any;
  usersIsDemoBtn?: any;
  showActionBtn?: boolean;
  deletedItem?: string;
  isFetching?: boolean;
};

const TableData = (props: Props) => {
  const {
    columns,
    data = [],
    page,
    setPage = () => null,
    limit,
    setLimit = () => null,
    onDelete = () => null,
    openUpdateModal = () => null,
    setUpdatedId,
    setUpdatedState = () => null,
    editLabel,
    deleteLabel,
    disabled = false,
    estimateBtnDisbale = true,
    estimateBtn = false,
    campaignStatusBtn = () => null,
    loading,
    loadingIsDemo,
    usersStatusBtn = () => null,
    usersIsDemoBtn = () => null,
    showActionBtn = true,
    deletedItem,
    isFetching,
  } = props;
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("email")
  const [tableData, setTableData] = useState<any>([]);
  const [updateStatusCampaignId, setUpdateStatusCampaignId] = useState(null);
  const [updateStatusUserId, setUpdateStatusUserId] = useState(null);
  const [updateIsDemoUserId, setUpdateIsDemoUserId] = useState(null);
  // const [pageChangeLoading, setPageChangeLoading] = useState<boolean>(false)

  useEffect(() => {
    setTableData(data?.results);
  }, [data]);

  const handleLimitChange = (e) => {
    setPage(1);
    setLimit(e.target.value);
  };
  
  const renderActiveStatusForUser = (item: any) => {
    return (
      <TableCell>
        <Button
          sx={{
            display: "flex",
            gap: 1,
            background: "#f4f4f4",
            width: "180px",
          }}
          onClick={() => {
            setUpdateStatusUserId(item.id);
            usersStatusBtn({
              id: item?.id,
              status: item?.is_active ? "False" : "True",
            });
          }}
          disabled={loggedInUser === item?.email}
        >
          <LoopIcon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...(updateStatusUserId === item.id &&
                loading && {
                  animation: "rotate 1s infinite linear",
                }),
              "@keyframes rotate": {
                from: {
                  transform: "rotate(0deg)",
                },
                to: {
                  transform: "rotate(360deg)",
                },
              },
            }}
          />

          {item?.is_active ? "Active" : "Inactive"}
        </Button>
      </TableCell>
    );
  };

  const renderDemoAccountForUser = (item: any) => {
    return (
      <TableCell>
        <Button
          sx={{
            display: "flex",
            gap: 1,
            background: "#f4f4f4",
            width: "180px",
          }}
          onClick={() => {
            setUpdateIsDemoUserId(item.user_profile.id);
            usersIsDemoBtn({
              id: item.user_profile.id,
            });
          }}
        >
          <LoopIcon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...(updateIsDemoUserId === item?.user_profile.id &&
                loadingIsDemo && {
                  animation: "rotate 1s infinite linear",
                }),
              "@keyframes rotate": {
                from: {
                  transform: "rotate(0deg)",
                },
                to: {
                  transform: "rotate(360deg)",
                },
              },
            }}
          />

          {item?.user_profile?.is_demo_account
            ? "Demo Account"
            : "Non Demo Account"}
        </Button>
      </TableCell>
    );
  };

  const renderCampaignStatus = (item: any) => {
    return (
      <TableCell>
        <Button
          sx={{
            display: "flex",
            gap: 1,
            background: "#f4f4f4",
            width: "180px",
          }}
          disabled={item.status === "Draft"}
          onClick={() => {
            setUpdateStatusCampaignId(item.id);
            campaignStatusBtn({
              id: item?.id,
              status:
                item?.status === "Ballpark Ready"
                  ? "Gathering Data"
                  : "Ballpark Ready",
            });
          }}
        >
          <LoopIcon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...(updateStatusCampaignId === item.id &&
                loading && {
                  animation: "rotate 1s infinite linear",
                }),
              "@keyframes rotate": {
                from: {
                  transform: "rotate(0deg)",
                },
                to: {
                  transform: "rotate(360deg)",
                },
              },
            }}
          />

          {item?.status}
        </Button>
      </TableCell>
    );
  };

  return (
    <Card
      sx={{
        position: "relative",
      }}
    >
      {(deletedItem || isFetching) && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
          }}
        >
          <Loader />
        </Box>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns?.map((item) => {
                return <TableCell key={item?.label}>{item?.label}</TableCell>;
              })}
              {showActionBtn && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((item: any) => {
              return (
                <TableRow hover key={item?.id}>
                  {columns?.map((column, index) => {
                    if (column.accessKey === "active_status") {
                      return renderActiveStatusForUser(item);
                    }

                    if (column.accessKey === "is_demo_account") {
                      return renderDemoAccountForUser(item);
                    }

                    if (column.accessKey === "status") {
                      return renderCampaignStatus(item);
                    }
                    return (
                      <TableCell key={index}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                          sx={{
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {column.accessor(item)}
                        </Typography>
                      </TableCell>
                    );
                  })}
                  {showActionBtn && (
                    <TableCell align="right">
                      {estimateBtn && (
                        <Tooltip title="Open Estimates" arrow>
                          <IconButton
                            sx={{
                              "&:hover": {
                                background: "rgba(34, 51, 84, 0.1)",
                              },
                              color: "rgb(0 0 0)",
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => navigate(`/estimates/${item?.id}`)}
                            disabled={
                              estimateBtnDisbale || item.status === "Draft"
                            }
                          >
                            <OpenInNewIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip title={editLabel} arrow>
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: "rgba(34, 51, 84, 0.1)",
                            },
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => {
                            openUpdateModal(true);
                            setUpdatedId(item.id);
                            setUpdatedState(item);
                          }}
                          disabled={
                            item.status === "Draft" || deletedItem === item?.id
                          }
                        >
                          <EditTwoToneIcon
                            fontSize="small"
                            sx={{
                              color: "rgb(85, 105, 255)",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={deleteLabel}>
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: "rgba(34, 51, 84, 0.1)",
                            },
                            color: "rgb(255, 25, 67)",
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => {
                            onDelete(item.id);
                          }}
                          disabled={disabled || deletedItem === item?.id}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box p={2}>
        <TablePagination
          component="div"
          count={data?.count}
          onPageChange={(event, newPage) => {
            setPage(newPage);
          }}
          onRowsPerPageChange={handleLimitChange}
          page={page - 1}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
          ActionsComponent={(props) => (
            <Pagination
              {...props}
              count={Math.ceil(data?.count / limit)}
              color="primary"
              page={page}
              onChange={(event, newPage) => {
                setPage(newPage);
              }}
            />
          )}
        />
      </Box>
    </Card>
  );
};

export default TableData;
