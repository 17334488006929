import { Alert, AlertTitle } from "@mui/material"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "src/redux/snackbar";

const ReactAlert = () => {
    const snackbar = useSelector((state: any) => state.snackBar);
    const dispatch = useDispatch();

    useEffect(() => {
        if (snackbar?.isOpen) {
            onCloseSnackbar();
        }
    }, [snackbar]);
    const onCloseSnackbar = () => {
        const messageLength = snackbar?.message?.length;
        const duration = messageLength > 200
            ? messageLength * 25
            : 3000;
        setTimeout(() => {
            dispatch(closeSnackbar());
        }, duration);
    };

    return (
        snackbar?.isOpen ?
            <Alert severity={snackbar.type}
                variant="filled"
                id="toast-message"
                sx={{
                    position: "fixed",
                    top: 0,
                    right: '5px',
                    zIndex: 100
                }}>
                <AlertTitle sx={{
                    fontWeight: "700"
                }}> {snackbar.title}</AlertTitle >
                {snackbar?.message}
            </Alert>
            : <></>
    )


}

export default ReactAlert