import { Divider, IconButton, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import React from 'react'

interface SearchBarProps {
    placeholder: string,
    value: string,
    onChange: (e) => void,
}
const SearchBar = (props: SearchBarProps) => {
    const { placeholder, value, onChange } = props;
    return (
        <Paper

            sx={{
                p: '2px 4px', display: 'flex', alignItems: 'center', width: "400px",
                "@media screen and (max-width: 600px)": {
                    width: "100%"
                },
                mt: 2, mb: 1
            }}
        >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                inputProps={{ placeholder }}
                value={value}
                onChange={onChange}
            />


        </Paper>
    )
}

export default SearchBar