import {
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useMutation, useQuery } from "react-query";
import Loader from "src/components/Loader/Loader";
import { useDispatch } from "react-redux";
import { openSnackbar } from "src/redux/snackbar";
import SearchInput from "src/components/SearchInput/SearchInput";
import { getCountryByRegion, getRegionOptions } from "src/services/dropDownOptins";
import { calculateRegionFactor } from "src/services/region";


const GeographyFactor = () => {
  const [regionOptions, setRegionOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [regionId, setRegionId] = useState([]);
  const [calculatedFactor, setCalculatedFactor] = useState();
  const [factor, setFactor] = useState({
    regions: [],
    countries: [],
    removedRegionId: null,
  });

  useEffect(() => {
    if (factor.removedRegionId !== null && factor.removedRegionId !== undefined) {
      const updatedCountries = factor.countries.filter((country) => (
        country.region !== factor.removedRegionId
      ))
      setFactor({
        ...factor,
        countries: updatedCountries
      })
    }
  }, [factor.removedRegionId])

  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();

  const { isLoading: regionOptionsLoading, refetch: refetchRegionOptions } =
    useQuery(
      "regionOptions",
      getRegionOptions,

      {
        enabled: false,
        onSuccess: (data) => {
          setRegionOptions(data?.results);
        },
      }
    );

  useEffect(() => {
    refetchRegionOptions();
  }, []);


  const { refetch: refetchCountries } = useQuery(
    ["regionOption", regionId],
    async () => {
      if (!regionId.length) return;
      try {
        setCountriesOptions([]);
        const response = await getCountryByRegion(regionId);
        setCountriesOptions([...response]);
      } catch (error) {
        throw new Error("An error occurred while fetching data.");
      }
    },
    {
      enabled: false,
      onSuccess: (data) => { },
    }
  );
  useEffect(() => {
    refetchCountries();
  }, [regionId]);

  useEffect(() => {
    if (regionOptions?.length && factor?.regions.length) {
      const matchingItems = [];
      factor.regions.forEach((region) => {
        const matchingItem = regionOptions.find((item) => item.name == region);
        if (matchingItem) {
          matchingItems.push(matchingItem.id);
        }
      });

      setRegionId([...matchingItems]);
    }
  }, [factor.regions]);

  const { mutate, isLoading, reset } = useMutation(
    (data: any) => calculateRegionFactor(data),
    {
      onError: (error) => {
        dispatch(
          openSnackbar({
            message: "Error occures",
            title: "Error",
            type: "error",
          })
        );
        reset();
      },
      onSuccess: (resp) => {
        setCalculatedFactor(resp.data.result);
      },
    }
  );

  const submitHandler = () => {
    setIsSubmitted(true);
    if (factor.regions.length < 1) {
      return;
    }
    mutate(factor)

  };

  return (
    <>
      <Helmet>
        <title>Geography Factor Calculator</title>
      </Helmet>
      <PageTitleWrapper>


        <Container maxWidth="lg" >

          <Typography
            variant="h3"
            sx={{
              textTransform: "capitalize",
              marginLeft: "-25px",
              marginBottom: "10px"
            }}
          >
            Calculate Geography Factor
          </Typography>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            spacing={3}
            pt={5}
            gap={5}
          >
            <SearchInput
              name="region"
              id="region"
              label="Choose Region"
              multiple={true}
              sx={{
                width: "48%",
              }}
              options={regionOptions?.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              onChange={(newValue) => {
                const values = newValue?.map((item) => {
                  if (typeof item === "object" && item !== null) {
                    return item.label;
                  } else {
                    return item;
                  }
                });
                const removedRegion = factor.regions.find(region => !values.includes(region));

                const removedRegionId = regionOptions.find(option => option.name === removedRegion)?.id;
                setFactor({
                  ...factor,
                  regions: values,
                  removedRegionId: removedRegionId
                });
              }}
              error={isSubmitted && factor?.regions?.length === 0}
              value={factor?.regions}
              isLoading={regionOptionsLoading}
              disableClearable={true}
            />


            <SearchInput
              name="country"
              id="country"
              label="Choose Country"
              multiple={true}
              sx={{
                width: "48%",
              }}
              options={countriesOptions?.map((item) => ({
                label: item.name,
                value: item.id,
                region: item.region
              })).filter(option => !factor?.countries?.find(c => c.value === option.value))}
              onChange={(newValue) => {
                const values = newValue?.map((item) => {
                  if (typeof item === "object" && item !== null) {
                    return {
                      label: item.label,
                      value: item.value,
                      region: item.region
                    };
                  } else {
                    return item;
                  }
                });
                setFactor({
                  ...factor,
                  countries: values,
                });
              }}
              // error={isSubmitted && factor?.countries?.length === 0}
              value={factor?.countries}
              isLoading={regionOptionsLoading}
              disableClearable={true}
            />

            <Button
              sx={{
                border: "1px solid black",
                width: "30%",
                backgroundColor: "black",
                color: "white",
                ":hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              onClick={submitHandler}
              disabled={isLoading}
            >
              {
                isLoading &&
                <Loader size={20} color={"inherit"} />
              }
              Submit
            </Button>

            <Typography
              variant="h6"
              sx={{
                textTransform: "capitalize",
              }}
            >
              Calculated Geography Factor: {calculatedFactor}
            </Typography>

          </Grid>
        </Container>


      </PageTitleWrapper>


    </>
  );
};

export default GeographyFactor;
