import api from "./api";

export const COLUMNS = [
  {
    label: "Followers Start Range",
    accessor: (row: any) => row?.followers_start_range,
  },
  {
    label: "Followers End Range",
    accessor: (row: any) => row?.followers_end_range,
  },
  { label: "Weight", accessor: (row: any) => row?.weight },
];

export const getInstaFollowersWeight = async (page?: any, limit?: any) => {
  const response = await api.get(
    `/instagram-followers-weight?page=${page}&page_size=${limit}`
  );
  return response.data;
};

export const addInstaFollowersWeight = async (data: any) => {
  const response = await api.post(
    `/instagram-followers-weight/`,
    JSON.stringify(data)
  );
  return response;
};

export const updateInstaFollowersWeight = async (data: any, id: string) => {
  const response = await api.put(
    `/instagram-followers-weight/${id}/`,
    JSON.stringify(data)
  );
  return response;
};

export const deleteInstaFollowersWeight = async (id: string) => {
  const response = await api.delete(`/instagram-followers-weight/${id}/`);
  return response;
};

export const getInstaFollowersFactor = async (number: any) => {
  const response = await api.get(
    `/api/instagram-followers-factor?instagram_followers=${number}`
  );
  return response;
};
