import axios from "axios";
import jwt_decode from "jwt-decode";

interface DecodedToken {
  exp: number;
}

export const getNewAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  // Check if refresh token exists
  if (!refreshToken) {
    // Logout the user 
    return false;
  }

  try {
    // Decode the refresh token and verify its structure
    const decodedToken = jwt_decode<DecodedToken>(refreshToken);
    
    if (decodedToken && typeof decodedToken.exp === 'number') {
      const expirationTime = decodedToken.exp * 1000; // Expiration time in milliseconds
      const currentTime = new Date().getTime(); // Current time in milliseconds

      // Check if refresh token is expired
      if (currentTime > expirationTime) {
            // Logout the user 
            return false;
      }
      // Proceed with token refresh logic
      const data = { refresh: refreshToken };
      const response = await axios.post("https://ringo-be.trailfive.com/api/token/refresh/", data);

      if (response.status === 200 && response.data?.access) {
        return response.data.access;
      } else {
            // Logout the user 
            return false;
      }
    } else {
        // Logout the user 
        return false;
    }
  } catch (error) {
    // Handle errors
    console.error("Error refreshing token:", error);
    // Logout the user 
    return false;
  }
};


