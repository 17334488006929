import { Box, Dialog, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';


export interface ModalProps {
    title?: string,
    open: boolean;
    onClose: () => void;
    children: ReactNode;
}

const Modal = (props: ModalProps) => {
    const { onClose, open, children, title } = props;

    return (
        <Dialog open={open} onClose={onClose}
            fullWidth={true}
            maxWidth={"md"}
            hideBackdrop={true}
        >
            {
                title &&
                <DialogTitle sx={{
                    margin: "auto",
                    fontWeight: "bold",
                    fontSize: "25px"
                }}>{title}</DialogTitle>
            }
            <Box onClick={onClose}
                sx={{
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    cursor: "pointer"

                }}
            >
                <CloseIcon sx={{
                    ":hover": {
                        color: "#999"
                    }
                }} />
            </Box>
            <Box sx={{
                // marginY: "20px"
                marginBottom: "20px"
            }}>

                {children}
            </Box>
        </Dialog>
    )
}

export default Modal