import api from "./api";

export const COLUMNS = [
  { label: "NAME", accessor: (row: any) => row?.name },
  { label: "Weight", accessor: (row: any) => row?.weight },
];

export const getRegion = async (page?: any, limit?: any) => {
  const response = await api.get(`/region?page=${page}&page_size=${limit}`);
  return response.data;
};
export const addRegion = async (data: any) => {
  const response = await api.post(`/region/`, JSON.stringify(data));
  return response;
};
export const updateRegion = async (data: any, id: string) => {
  const response = await api.put(`/region/${id}/`, JSON.stringify(data));
  return response;
};
export const deleteRegion = async (id: string) => {
  const response = await api.delete(`/region/${id}/`);
  return response;
};

export const calculateRegionFactor = async (data: any) => {
  const region = encodeURIComponent(data.regions).toString();
  const country = data.countries.map(
    (item: any) => encodeURIComponent(item?.label).toString()
  );
  const response = await api.get(
    `/api/getgeographyfactorAPIView?region=${region}&country=${country}`
  );
  return response;
};
