import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
type Props = {
  label: string;
  name: string;
  btnName: string;
  disabled?: boolean;
  setIsopen?: any;
};

const Header = (props: Props) => {
  const { label, name, btnName, disabled, setIsopen } = props;

  const username = localStorage.getItem("username");

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {label}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            textTransform: "capitalize",
          }}
        >
          {username}, these are your recent {name}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
          onClick={setIsopen}
          disabled={disabled}
        >
          {btnName}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
