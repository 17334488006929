import { useRoutes } from 'react-router-dom';
import routes from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from './redux/user';
import ReactAlert from './components/Alerts/ReactAlert';
import "./app.css"



function App() {

  const dispatch = useDispatch();
  const routing = useRoutes(routes);





  useEffect(() => {
    dispatch(setUser({
      name: localStorage.getItem("username"),
      access: localStorage.getItem("token"),
      refresh: localStorage.getItem("refreshToken")
    }));
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {routing}
        <ReactAlert />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
