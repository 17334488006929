import axios from "axios";
import { SERVER_URL } from "../config";
import jwt_decode from "jwt-decode";
import { isPast, fromUnixTime } from "date-fns";
import { getNewAccessToken } from "./refresh";

const BASE_URL = SERVER_URL || "https:";
interface TokenDecode {
  exp: number;
  iat: number;
  jti: string;
  token_type: string;
  user_id: number;
}

const createConfig = (token: string) => {
  return {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

const createConfigWithOutToken = () => {
  return {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};

const validateAndRefreshToken = async () => {
  const token = localStorage.getItem("token");

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("refreshToken");
    window.location.href = "/auth/login";
  };
  if (!token) {
    return false;
  }
  const decode: TokenDecode = jwt_decode(token);
  const date = fromUnixTime(decode.exp);
  if (isPast(date)) {
    const response = await getNewAccessToken();
    if (!response) {
      logout();
    }
    localStorage.setItem("token", response);
    return response;
  }
  return localStorage.getItem("token");
};

const api = {
  get: async (url: string) => {
    const token = await validateAndRefreshToken();
    const config = createConfig(token);
    return await axios.get(`${BASE_URL}${url}`, config);
  },

  post: async (url: string, data: any, withToken: boolean = true) => {
    const token = withToken ? await validateAndRefreshToken() : null;
    const config = token ? createConfig(token) : createConfigWithOutToken();
    return await axios.post(`${BASE_URL}${url}`, data, config);
  },

  patch: async (url: string, data: any) => {
    const token = await validateAndRefreshToken();
    const config = createConfig(token);
    return await axios.patch(`${BASE_URL}${url}`, data, config);
  },

  put: async (url: string, data: any) => {
    const token = await validateAndRefreshToken();
    const config = createConfig(token);
    return await axios.put(`${BASE_URL}${url}`, data, config);
  },

  delete: async (url: string) => {
    const token = await validateAndRefreshToken();
    const config = createConfig(token);
    return await axios.delete(`${BASE_URL}${url}`, config);
  },
};

export default api;
