import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: '',
    isOpen: false,
    type: 'success',
    title: "",
};

export const snackbarSlice: any = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar: (state: any, action: any) => {
            const { message, type, title } = action.payload;
            state.message = message;
            state.isOpen = true;
            state.type = type || 'success';
            state.title = title
        },
        closeSnackbar: (state: any) => {
            state.message = '';
            state.isOpen = false;
            state.type = 'success';
            state.title = ""
        }
    }
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;