import api from "./api";

export const getCoverVersionOptions = async () => {
  try {
    const response = await api.get(`/coverversion`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getLyricAdjustmentOptions = async () => {
  try {
    const response = await api.get(`/lyricadjustment`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getBrandCategories = async () => {
  try {
    const response = await api.get(`/brandcategories`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getRegionOptions = async () => {
  try {
    const response = await api.get(`/region`);

    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};
export const getTerritoryOptions = async () => {
  try {
    const response = await api.get(`/territory`);

    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getCountryByRegion = async (ids) => {
  const data = {
    regions: [...ids],
  };

  try {
    const response = await api.post(
      `/country/countriesByRegion/`,
      JSON.stringify(data)
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getSubMediaOptions = async (ids) => {
  const data = {
    medias: [...ids],
  };

  try {
    const response = await api.post(
      `/submedia/SubMediaByMedia/`,
      JSON.stringify(data)
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getDurationOptions = async () => {
  try {
    const response = await api.get(`/duration`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getMediaOptions = async () => {
  try {
    const response = await api.get(`/media`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getExclusivityOptions = async () => {
  try {
    const response = await api.get(`/exclusivity`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getArtistWillingnessOptions = async () => {
  try {
    const response = await api.get(`/artistwillingness`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getCountryAndRegion = async () => {
  try {
    const response = await api.get(`/region/RegionsAndCountries/`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching data.");
  }
};

export const getUsersList = async (page?: any, limit?: any, search?: any) => {
  const response = await api.get(`/User/getUsersList/`);
  return response;
};
