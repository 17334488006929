import api from "./api";

export const COLUMNS = [
  {
    label: "Start Range",
    accessor: (row: any) => row?.start_range,
  },
  {
    label: "End Range",
    accessor: (row: any) => row?.end_range,
  },
  { label: "Weight", accessor: (row: any) => row?.weight },
];

export const getMonthlyListenerSpotify = async (page?: any, limit?: any) => {
  const response = await api.get(
    `/legacy-artists-monthly-listeners-spotify?page=${page}&page_size=${limit}`
  );
  return response.data;
};

export const addMonthlyListenerSpotify = async (data: any) => {
  const response = await api.post(
    `/legacy-artists-monthly-listeners-spotify/`,
    JSON.stringify(data)
  );
  return response;
};

export const updateMonthlyListenerSpotify = async (data: any, id: string) => {
  const response = await api.put(
    `/legacy-artists-monthly-listeners-spotify/${id}/`,
    JSON.stringify(data)
  );
  return response;
};

export const deleteMonthlyListenerSpotify = async (id: string) => {
  const response = await api.delete(
    `/legacy-artists-monthly-listeners-spotify/${id}/`
  );
  return response;
};
