import api from "./api";
import { formatDistanceToNow } from "date-fns";

export const COLUMNS = [
  { label: "User", accessor: (row: any) => row?.username },
  { label: "Message", accessor: (row: any) => row?.message },
  {
    label: "Status",
    accessor: (row: any) => (row?.is_read ? "Read" : "Not Read"),
  },
  {
    label: "Created",
    accessor: (row: any) =>
      row?.created_at
        ? formatDistanceToNow(new Date(row.created_at), { addSuffix: true })
        : "",
  },
];

export const getAllNotifications = async (page?: any, limit?: any) => {
  const response = await api.get(
    `/notifications?page=${page}&page_size=${limit}`
  );
  return response.data;
};
export const addNotification = async (data: any) => {
  const response = await api.post(`/notifications/`, JSON.stringify(data));
  return response;
};
export const updateNotification = async (data: any, id: string) => {
  const response = await api.put(`/notifications/${id}/`, JSON.stringify(data));
  return response;
};
export const deleteNotification = async (id: string) => {
  const response = await api.delete(`/notifications/${id}/`);
  return response;
};
